/* These functions are helpers for accessing package and item data.
The design is three layers with a possible fourth.
From the lowest layer, 1, at the bottom of the call stack,
to the top layer, the calling functions.

1. The accessor function taking the data object and `opts` object to pass the
   accessor special values. These get the values and return them in an object.
2. A generic accessor function (getData) which takes an object argument full of
   options needed to do a configuration driven lookup of the correct accessor.
3. A package data and item data getter function which take minimal arguments
   and marshal the settings needed by the generic, config-driven, getter.
   They take the data objects and an object which becomes the `fields` property
   of the `opts` used by layer 1 and 2.
4. (Not implemented currently) A convenience layer where only the content or item
   object is passed, e.g. `getFirstLiveBlogItem(content)`. Each layer up the stack
   is there to marshal arguments for the lower layer and this is the level where
   one can give very specific names, essentially moving arguments into the function
   name namespace by using function names that imply the specific settings
   needed by the generic layer 2 specifically. For commonly used calls
   these functions can simplify the life of programmers, less typing and
   isolation from knowledge of the generic layers option schema, since usually they
   don't care and accessing the same data over and over for an extended period of
   time.

  Developers are calling 3 (or 4). People adding new accessors are able to
  make changes directly to the accessor lookup tables, i.e. by naming their
  data with a key and adding their function as its property, it becomes
  available at layer 3, and if desired a layer 4 function could be added.

Notes:
  * A analogous stack for setting could be built and probably should be,
    so package defaults can handle many of the defaults handled throughout the
    package and now in these accessor functions.
  *
*/
import get from 'lodash.get';
import { BADGES } from 'components/BadgeLabel/constants';

// Accessor Functions and accessorsLibrary
//
export const packageDataAccessors = {
  firstItemOfType: (packageData, opts = {}) => {
    // need opts for `itemType`
    const { fields: { itemType } = {} } = opts;
    if (!itemType) return null;

    const { items } = packageData;
    if (!items) return null;

    const foundItem = items.find((item) => item.type === itemType);
    return foundItem;
  },
  packageMetadata: (packageData) => packageData.metadata || {},
};

export const itemDataAccessors = {
  liveBlogAuthorNames: (itemData) => {
    const authors = get(itemData, 'item.authors', null);
    if (!authors) return null;
    const authorNames = authors.map((item) => get(item, 'person.name'));
    return authorNames;
  },
  multiStorylineItemData: (itemData, opts = {}) => {
    const { fields } = opts;
    const { content, showLive, columnIndex } = fields;
    const packageMetadata = content.metadata;
    const {
      computedValues,
      item,
      metadata,
      related,
    } = itemData;

    const {
      badge,
      hasBadge,
      hasEyebrow = true,
      badgeText,
      // @@PKGDEFAULT
      showAuthor: hasAuthor = false,
      showDek,
      showTimestamp,
      columns,
    } = metadata;

    const {
      dek,
      headline: computedHeadline,
      unibrow,
      url,
    } = computedValues;

    const {
      // @@PKGDEFAULTS stray default could move to packageDefaults
      headlineSize = 'large',
    } = content.metadata;

    const storyTypes = {
      IMPORTANT: 'important',
      STANDARD: 'standard',
    };
    const getBadgeType = () => {
      if (hasBadge && !badge) {
        // @@PKGDEFAULTS
        return 'BREAKING';
      }
      return (hasBadge && badge) || BADGES.EYEBROW;
    };

    const headline = computedHeadline || item?.headline?.primary;
    const hasHeadline = !!headline && !!headline.length;
    const headlineType = headlineSize;
    const showHeadlineOnly = !hasAuthor && !showDek && !showTimestamp;
    const headlineUrl = itemData.type === 'video' && metadata?.headlineUrlOverride ? metadata?.headlineUrlOverride
      : (itemData?.metadata?.embedHeadlineUrl || url);

    const leadColumn = content.metadata.leadColumn ? content.metadata.leadColumn : 'none';
    const storyType = content.metadata?.storyType || storyTypes.STANDARD; // @@PKGDEFAULTS
    const hasRelatedContent = Array.isArray(related) && related.length > 0;
    const authors = item?.authors || [];
    const delimiter = '/';
    const multiHeadlineSize = metadata.headlineSize ? metadata.headlineSize : 'standard';
    const showAuthor = hasAuthor && item?.authors?.length > 0;
    // @@PKGDEFAULTS Lots of things to move to packageDefaults, use accessors, and implement defaults there.
    const multiMediaSize = metadata.mediaSize ? metadata.mediaSize : 'multiStandard';
    const isSmallMedia = multiMediaSize === 'multiSmall';
    const relatedsDisplay = itemData?.metadata?.relatedsDisplay === 'sideBySide' ? 'sideBySide' : 'stacked';
    // parent controlled const showVerticalBorder = content?.metadata?.showVerticalBorderA;
    const isLiveBlog = itemData.type === 'liveBlog';
    const itemType = itemData.type;
    const aspectRatio = multiMediaSize === 'multiSmall' ? itemData?.metadata?.aspectRatio : '3 / 2';
    const oneToOneAspect = aspectRatio === '1 / 1';

    const isVideoContent = itemData.type === 'video';

    const multiStoryData = {
      size: multiMediaSize,
      isLiveVideo: showLive,
      isMulti: true,
      isVideoContent,
    };

    let colLead = false;
    if ((leadColumn === 'Column1Lead' && columnIndex === 0)
      || (leadColumn === 'Column2Lead' && columnIndex === 1)) {
      colLead = true;
    }
    const itemDataValues = {
      content,
      metadata,
      item,
      related,

      // item metadata
      badge,
      hasBadge,
      hasEyebrow,
      badgeText,
      hasAuthor,
      showDek,
      showTimestamp,
      columns, // set by packageDefaults

      // computed values
      dek,
      computedHeadline,
      unibrow,
      url,

      // packageMetadata
      packageMetadata,
      headlineSize,
      headlineType,

      // built
      storyTypes,
      getBadgeType,
      multiStoryData,

      // processed
      headline,
      hasHeadline,
      showHeadlineOnly,
      storyType,
      hasRelatedContent,
      authors,
      delimiter,
      multiHeadlineSize,
      showAuthor,
      isSmallMedia,
      multiMediaSize,
      relatedsDisplay,
      isLiveBlog,
      itemType,
      aspectRatio,
      oneToOneAspect,
      isVideoContent,
      colLead,
      leadColumn,
      headlineUrl,
    };

    return itemDataValues;
  },
};

const accessorsLibrary = {
  item: itemDataAccessors,
  package: packageDataAccessors,
};


// Generic Function
// `getData` is configuration-driven and uses `sourceType` and `dataName` to select
//  an accessor function to call. It's the control system for higher level
//  functions that are more convenient due to being more specific and less abstract.
//

export function getData({
  sourceType, source, fields, dataName, fallback,
} = {}) {
  if (!dataName || !source) return fallback;

  const accessorsDict = accessorsLibrary[sourceType] || [];
  const accessor = accessorsDict[dataName];
  let value;
  if (accessor) {
    value = accessor(source, {
      dataName,
      sourceType,
      fields,
    });
  } else {
    value = get(source, dataName);
  }

  if (value === undefined && fallback !== undefined) {
    value = fallback;
  }

  return value;
}

/* // /////
// Schema Access Layer
    Accessing data by "dataName" based on known schema and a given object.

    A complete list of available package data accessors are in the
    `packageDataAccessors` and `itemDataAccessors` object above.
    The below may be out of date, please update or remove if so.

    Named Package Data:
      firstItemOfType, { itemType (required)}
        Returns first object in `packageData.items` of `itemType`.
      packageMetadata,
        Returns `packageData.metadata`.

    Named Item Data:
      liveBlogAuthors
        Returns list of all authors in the item.
      multiStorylineItemData { content (required), showLive, columnIndex }
        Returns an objects of properties from itemData, packageData
        and computed values needed by MultiStoryline and sub-components.
*/
export function getPackageData(packageData, dataName, fields) {
  return getData({
    sourceType: 'package',
    source: packageData,
    dataName,
    fields,
  });
}

// Available item data accessors are in the itemDataAccessors object above.
export function getItemData(itemData, dataName, fields) {
  return getData({
    sourceType: 'item',
    source: itemData,
    dataName,
    fields,
  });
}
