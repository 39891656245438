import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.themed.scss';
/* eslint react/no-unused-prop-types: 0 --
   Reason: Rule disabled b/c eslint is confused by `prepareProps` idiom.
*/

function prepareProps(props) {
  const { children } = props;
  // calced
  const numColumns = children.length;

  // vertical dividers must all be set, default to false
  // but may be default if not sent
  let { verticalDividers } = props;
  if (verticalDividers.length === 0) {
    verticalDividers = [...Array(numColumns - 1).keys()].map(() => false);
  }

  return {
    ...props,
    verticalDividers,
    numColumns: children.length,
  };
}

export function ColumnsContainer(props) {
  const {
    children,
    leadColumn,
    stackLeadColumnFirstOnMobile,
    verticalDividers, // populated if not filled
    numColumns, // calculated
  } = prepareProps(props);

  const hasColumn1Lead = leadColumn === 'Column1Lead';
  const hasColumn2Lead = leadColumn === 'Column2Lead';
  const hasColumn3Lead = leadColumn === 'Column3Lead';
  const hasLeadColumn = hasColumn1Lead || hasColumn2Lead || hasColumn3Lead;

  // zip numColumns and dividers
  const childrenWithDividers = children.reduce((ac, columnJsx, columnIndex) => {
    ac.push(columnJsx);

    const innerJoin = columnIndex < numColumns - 1;
    const insertDivider = innerJoin && verticalDividers[columnIndex];
    const columnNumber = columnIndex + 1;
    const dividerOrderClass = `column-divider-order-${columnNumber}`;
    const columnSepName = `columnSeparator-${columnNumber}`;

    // regular vertical divider between columns
    if (insertDivider) {
      ac.push((
        <div
          key={columnSepName}
          className={classNames(
            'columnSeparator',
            columnSepName,
            dividerOrderClass,
          )}
        />
      ));
    }
    // mobile only horizontal divider at bottom of inner columns
    if (innerJoin) {
      const mobileColumnSepName = `mobile-${columnSepName}`;
      ac.push((
        <div
          key={mobileColumnSepName}
          className={classNames(
            'mobileColumnSeparator',
            mobileColumnSepName,
            dividerOrderClass,
          )}
        />
      ));
    }
    return ac;
  }, []);
  //
  const [showVerticalBorderA, showVerticalBorderB] = verticalDividers;
  //
  return (
    <div className={classNames(
      'columns-container',
      {
        'two-column': numColumns === 2,
        'three-column': numColumns === 3,
        'has-lead-column': hasLeadColumn,
        'no-lead-column': !hasLeadColumn,
        'column-1-lead': hasColumn1Lead,
        'column-2-lead': hasColumn2Lead,
        'column-3-lead': hasColumn3Lead,
        'vertical-divider-A': showVerticalBorderA,
        'vertical-divider-B': showVerticalBorderB,
        'stack-column-2-first': stackLeadColumnFirstOnMobile && hasColumn2Lead,
      },
    )}
    >
      {childrenWithDividers}
    </div>
  );
}

ColumnsContainer.propTypes = {
  children: PropTypes.node.isRequired,
  leadColumn: PropTypes.string,
  verticalDividers: PropTypes.arrayOf(PropTypes.bool),
  stackLeadColumnFirstOnMobile: PropTypes.bool,
};

ColumnsContainer.defaultProps = {
  leadColumn: null,
  verticalDividers: [],
  stackLeadColumnFirstOnMobile: false,
};
