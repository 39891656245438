import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.themed.scss';

export function ColumnOfItems(props) {
  const {
    children,
    className,
    columnName,
    rowModeGroupNumber,
    horizontalItemDividerClassName,
    rowModeDividerClassName,
  } = props;
  const numItems = children.length;

  const childrenWithDividers = children.reduce((ac, item, index) => {
    ac.push(item);
    const innerJoin = index < numItems - 1;
    if (innerJoin) {
      const dividerKey = `horizontal-item-divider-${columnName}-${index}`;
      ac.push((
        <div
          key={dividerKey}
          className={classNames(
            'coi-horizontal-item-divider',
            horizontalItemDividerClassName,
          )}
        />
      ));
    }
    // add rowMode-dividers
    if (rowModeGroupNumber) {
      const endOfRow = (index % rowModeGroupNumber) === (rowModeGroupNumber - 1);
      if (endOfRow && innerJoin) {
        const rowModeDividerKey = `row-mode-separator-${columnName}-${index}`;
        ac.push((
          <div
            key={rowModeDividerKey}
            className={classNames(
              'coi-row-mode-horizontal-divider',
              rowModeDividerClassName,
            )}
          />
        ));
      }
    }

    return ac;
  }, []);

  return (
    <div
      className={classNames('column-of-items', className)}
    >
      { childrenWithDividers }
    </div>
  );
}

ColumnOfItems.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  columnName: PropTypes.string,
  rowModeGroupNumber: PropTypes.number,
  horizontalItemDividerClassName: PropTypes.string,
  rowModeDividerClassName: PropTypes.string,
};

ColumnOfItems.defaultProps = {
  className: '',
  columnName: '',
  rowModeGroupNumber: 3,
  horizontalItemDividerClassName: '',
  rowModeDividerClassName: '',
};
