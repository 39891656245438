import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { package as PackagePropType } from 'lib/CustomPropTypes';
import BadgeLabel from 'components/BadgeLabel';
import { BADGES } from 'components/BadgeLabel/constants';
import { BylineList } from 'components/Byline';
import ContentTimestamp from 'components/ContentTimestamp';
import { Headline } from 'components/packages/Storyline/Headline';
import { StorylineMedia } from 'components/packages/Storyline/StorylineMedia';
import { RelatedContentTease } from 'components/RelatedContentTease';
import { LiveBlogLayout } from '../LiveBlogLayout';

import './styles.themed.scss';

export function StandardLayout({ content }) {
  if (!content) return null;

  const {
    items = [],
    metadata: packageMetadata = {},
  } = content;
  if (items.length === 0) return null;
  const hasLiveBlogArticle = items.some((item) => item?.type === 'liveBlog');
  if (hasLiveBlogArticle) return <LiveBlogLayout content={content} />;

  const sideRelatedRef = useRef(null);
  const [sideRelatedWidth, setSideRelatedWidth] = useState();
  useEffect(() => {
    const handleResize = () => {
      if (sideRelatedRef.current !== null) {
        setSideRelatedWidth(sideRelatedRef.current.offsetWidth);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sideRelatedRef]);

  // standard vs important storyType changes the headline size and order of components on the page
  const storyTypes = {
    IMPORTANT: 'important',
    STANDARD: 'standard',
  };
  const storyType = packageMetadata?.layoutType || storyTypes.STANDARD;

  const mainContentItem = {
    ...items[0],
  };

  const {
    computedValues = {},
    item = {},
    metadata = {},
  } = mainContentItem;

  let {
    related = {},
  } = mainContentItem;

  if (Array.isArray(related) && related.length > 0) {
    // filter over related content to ensure that no null values are passed through
    related = related.filter(Boolean);
  }
  const hasRelatedContent = Array.isArray(related) && related.length > 0;
  const { authors = [] } = item || {};

  const {
    dek,
    headline: computedHeadline,
    unibrow,
    url,
  } = computedValues;

  const {
    headlineSize = 'large',
    relatedContentWrapIndex: wrapIndexString = 5,
    mediaSize = 'medium',
  } = packageMetadata;
  const {
    badge,
    hasBadge,
    hasEyebrow = true,
    badgeText,
    // When video player is used, it has no such field,
    // therefore by default it is true to display players correctly
    showAuthor: hasAuthor = false,
    showDek,
    showTimestamp,
  } = metadata;
  const headline = computedHeadline
  || item?.headline?.primary
  || mainContentItem?.metadata?.embedHeadline;
  const headlineType = headlineSize;
  const storylineTimestamp = showTimestamp || mainContentItem?.metadata?.showTimestamp;
  const showMedia = mediaSize !== 'none';
  const showHeadlineOnly = !hasAuthor && !showDek && !showTimestamp && !showMedia;
  const showAuthor = hasAuthor && authors.length > 0;
  const delimiter = '/';
  const hasVisual = mediaSize !== 'none';
  const storylineEyebrow = unibrow?.url?.primary || mainContentItem?.computedValues?.unibrow?.url?.primary;
  const storylineDek = dek || mainContentItem?.computedValues?.dek;
  const storylineEyebrowText = unibrow?.text || mainContentItem?.computedValues?.unibrow?.text;
  const showStorylineDek = showDek || mainContentItem?.metadata?.showDek;
  const storylineHeadlineUrl = (items[0] != null && items[0].type === 'video' && metadata.headlineUrlOverride)
    ? metadata.headlineUrlOverride : (mainContentItem?.metadata?.embedHeadlineUrl || url);
  const removeDekBottomBorder = mediaSize === 'none' && !showAuthor && !storylineTimestamp;

  const getBadgeType = () => {
    if (hasBadge && !badge) {
      return 'BREAKING';
    }
    return (hasBadge && badge) || BADGES.EYEBROW;
  };

  // The returned relatedContentWrapIndex is string type,
  // when "Show Visual" is toggled off in curator, previous index value wasn't reset
  const relatedContentWrapIndex = showMedia ? Number(wrapIndexString) : 0;
  const showSideRelated = hasRelatedContent && showMedia && relatedContentWrapIndex > 0;

  // The relatedContentWrapIndex is a value from 0 to 5, selected by editors,
  // to determine how many related content items will stack adjacent to the image / media.
  // It will be an "off by one" value we can use to slice the related array.  Any remaining
  // items in the related array will then render in the bottom related section, under the image.

  // Bottom has zero related if there is no related provided (datatype error or length is 0),
  // or the provided relatedContentWrapIndex is larger than the number of related available in the array.
  const bottomRelatedCount = hasRelatedContent ? Math.max(0, related.length - relatedContentWrapIndex) : 0;
  const bottomRelatedThumbnailDisplayStatus = [];

  // bottomRelatedThumbnailDisplayStatus checks display media status for each bottom-related,
  // to aid vertically centerring related headlines for each bottom-related with media
  // (OR especially with a neighboring bottom-related on the same row with media)
  for (let i = 0; i < bottomRelatedCount; i += 1) {
    const relatedItem = related[relatedContentWrapIndex + i];
    const { computedValues: relatedComputedValues, metadata: relatedMetaData } = relatedItem;
    const { hasMedia: relatedHasMedia = false } = relatedMetaData;
    const displayTeaseImage = Boolean(relatedHasMedia
      && (relatedComputedValues?.teaseImage));
    bottomRelatedThumbnailDisplayStatus.push(displayTeaseImage);
  }

  const bottomNeighborLocalIndex = (localIndex) => {
    if (localIndex % 2 === 0) {
      return localIndex + 1;
    }
    return 2 * Math.floor(localIndex / 2);
  };

  const innerComponents = () => (
    <div className={classNames({
      'headline-center': storyType === storyTypes.IMPORTANT && headlineType === 'large',
    })}
    >
      <div className="standard-layout__badge">
        {hasEyebrow && (
          <BadgeLabel
            badgeType={getBadgeType()}
            badgeText={badgeText}
            eyebrowText={storylineEyebrowText}
            url={storylineEyebrow}
          />
        )}
      </div>
      <Headline
        text={headline}
        headlineSize={headlineType}
        storyType={storyType}
        url={storylineHeadlineUrl}
        showHeadlineOnly={showHeadlineOnly}
      />
      {showStorylineDek && (
        <div className={classNames(
          'standard-layout__dek',
          'publico-txt',
          'f3',
          'lh-copy',
          'fw4',
          {
            mb2: !removeDekBottomBorder,
          },
        )}
        >
          {storylineDek}
        </div>
      )}
      <div className={classNames(
        'standard-layout__author-timestamp-container',
        'founders-mono',
        'lh-none',
        'fw4',
        {
          mb2: mediaSize !== 'none',
        },
      )}
      >
        {showAuthor && (
          <BylineList
            authors={authors}
          />
        )}
        {showAuthor && storylineTimestamp && (
          <span className="ma1">
            {delimiter}
          </span>
        )}
        {storylineTimestamp && (
          <ContentTimestamp
            dateCreated={item?.dateCreated}
            datePublished={item?.datePublished}
            delimiter=", "
          />
        )}
      </div>
    </div>
  );
  // container-top sits above the media, container-side sits adjacent to the media.
  // which elements are hidden/shown depends on a combination of storyType and breakpoint
  return (
    <div
      className={classNames(
        'standard-layout',
        {
          'important-story': storyType === storyTypes.IMPORTANT,
          'standard-story': storyType !== storyTypes.IMPORTANT,
          'headline-large': headlineType === 'large',
        },
      )}
      data-testid={`standard-layout-${storyType}`}
    >
      {/* {container-top: above the image/media} */}
      <div className="standard-layout__container-top">
        {innerComponents()}
      </div>

      <div className="standard-layout__main-content-container">
        {/* {container-side: adjacent to the image/media} */}
        <div
          ref={showMedia ? sideRelatedRef : null}
          className={classNames(
            { 'standard-layout__container-side': hasVisual },
            `media-${mediaSize}`,
          )}
        >
          {storyType !== storyTypes.IMPORTANT && (
            <div className={classNames(
              'container-side__text-content',
              {
                'no-dek': !showDek,
                'no-author-timestamp': !showAuthor && !storylineTimestamp,
              },
            )}
            >
              {innerComponents()}
            </div>
          )}
          {showSideRelated && (
            <div className="standard-layout__adjacent-related">
              {
                related.map((relatedItem, index) => {
                  if (index >= relatedContentWrapIndex) {
                    return null;
                  }
                  return (
                    <div key={relatedItem.id}>
                      <hr className="my-line" />
                      <RelatedContentTease relatedItem={relatedItem} lastRelated={related.length - 1 === index} />
                    </div>
                  );
                })
              }
            </div>
          )}
        </div>
        {showMedia && (
          <StorylineMedia
            contentItem={mainContentItem}
            packageMetadata={packageMetadata}
          />
        )}
      </div>
      {bottomRelatedCount > 0 && (
        <div className="standard-layout__bottom-related">
          {
            related.map((relatedItem, index) => {
              if (index < relatedContentWrapIndex) {
                return null;
              }
              const localIndex = index - relatedContentWrapIndex;
              const isLeft = (index - relatedContentWrapIndex) % 2 === 0;

              // Used to handle "important" and "standard" layouts.
              // The storyline media position switches between left and right based on story type
              const isOnTheSide = (storyType !== storyTypes.STANDARD) !== isLeft;

              const isVerticallyCentered = bottomRelatedThumbnailDisplayStatus[localIndex]
                || bottomRelatedThumbnailDisplayStatus[bottomNeighborLocalIndex(localIndex)];

              // If the last single related takes the whole row (and is not under the media, but on the side),
              // the width is a fixed value depending on above flexboxes.
              const isLastOdd = isOnTheSide && (index === related.length - 1)
                && ((index - relatedContentWrapIndex) % 2 === 0);

              // If this related is going to be in the last row, then we're going to remove its
              // bottom margin due to a bug causing it to apply 16px extra margin
              const isAtBottomRow = (
                (related.length - relatedContentWrapIndex) % 2 === 1
                  && index === related.length - 1)
                || ((related.length - relatedContentWrapIndex) % 2 === 0
                  && index >= related.length - 2);


              const bottomRelatedStyles = showMedia ? {
                'standard-layout__bottom-related-card--side': isOnTheSide,
                'standard-layout__bottom-related-card--media-align': !isOnTheSide,
                'media-medium': mediaSize === 'medium',
                'media-large': mediaSize === 'large',
              } : 'standard-layout__bottom-related-card--no--media';

              return (
                <div
                  className={classNames(
                    'standard-layout__bottom-related-card',
                    bottomRelatedStyles,
                    { 'standard-layout__bottom-related-margin': isAtBottomRow },
                  )}
                  key={relatedItem.id}
                  style={isLastOdd ? {
                    width: sideRelatedWidth,
                    flexGrow: 0,
                  } : {}}
                >
                  <hr className={classNames('my-line')} />
                  <RelatedContentTease
                    relatedItem={relatedItem}
                    isVerticallyCentered={isVerticallyCentered}
                    lastRelated={related.length - 1 === index}
                  />
                </div>
              );
            })
          }
        </div>
      )}
    </div>
  );
}

StandardLayout.propTypes = {
  content: PackagePropType.isRequired,
};
